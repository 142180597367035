<template>
  <!-- 实验用房预约 -->
  <div class="con_contain">
    <div class="mainpage">
      <div class="head">
        <div class="searchcontain">
          <input class="elinput" placeholder="请输入实验用房" type="text" v-model="keyword" />

          <div class="searchbt" @click="searchbt">搜索</div>
        </div>
      </div>

      <div class="titlecategory">
        <div class="titlecategory-item">
          <div class="item-left">学院</div>
          <div class="item-right">
            <div :class="
                queryParams.college_id === ''
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              " @click.stop="academybtclick('')">
              全部
            </div>
            <div :class="
                queryParams.college_id === item.id
                  ? 'item-right-text item-right-text-heightlight'
                  : 'item-right-text'
              " @click.stop="academybtclick(item.id)" v-for="(item, index) in collegelist" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="image-contain">
        <div class="image-item">
          <div class="image-item-item" @click="todetail(item.id)" v-for="(item, index) in openroomlist" :key="index">
            <div class="card-contain">
              <el-image class="elimage" :src="item.lab_room_image" />
              <div class="card-title">{{ item.name }}<span v-if="item.lab_room_sn">({{item.lab_room_sn}})</span></div>
            </div>
          </div>
        </div>
        <div class="elpagination">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total" :page-size="12"
            :current-page="queryParams.page" @current-change="pageChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcollegelist } from "@/api/toptype";
import { getOpenRoomList } from "@/api/openroom";
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      academybtid: "",
      collegelist: [],
      total: 0,
      openroomlist: [],
      keyword: "",
      queryParams: {
        keyword: "",
        college_id: "",
        page: 1,
        per_page: 12,
      },
    };
  },
  computed: {},
  created() {
    this.getopenroomlist();
    this.getcollegelist({ record_type: 1 }); //record_type 是否获取全部记录 0：分页，1：全部
  },

  methods: {
    todetail(id) {
      this.roomid = id;
      this.$router.push({
        path: "/home/openroom/roomdetails",
        query: {
          id: id,
        },
      });
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val;
      this.getopenroomlist();
    },
    searchbt() {
      this.queryParams.keyword = this.keyword
      this.queryParams.page = 1;
      this.getopenroomlist();
    },
    academybtclick(id) {
      this.queryParams.college_id = id;
      this.getopenroomlist();
    },
    getcollegelist(params) {
      this.collegelist = [];
      getcollegelist(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              this.collegelist.push({
                id: item.id,
                name: item.name,
              });
            });
          }
        })
        .catch((error) => {});
    },

    getopenroomlist() {
      this.openroomlist = [];
      getOpenRoomList(this.queryParams)
        .then((response) => {
          this.total = response.data.total;
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((item) => {
              this.openroomlist.push({
                id: item.id,
                lab_centre_name: item.lab_centre_name,
                lab_name: item.lab_name,
                lab_room_image: item.lab_room_image,
                lab_room_sn: item.lab_room_sn,
                name: item.name,
              });
            });
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
<style scoped lang="scss">
.con_contain {
  display: flex;
  justify-content: center;
  align-items: center;
  .mainpage {
    background: #ffffff;
    width: 1760px;
    .head {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #288add;
        user-select: none;
        white-space: nowrap;
      }
      .searchcontain {
        position: relative;
        .elinput {
          width: 260px;
          height: 28px;
          border-radius: 25px;
          padding-left: 20px;
          border: 1px solid #3d84ff;
          &:focus {
            outline: none;
          }
        }
        .searchbt {
          position: absolute;
          top: 0px;
          right: 0px;
          margin-left: 17px;
          width: 85px;
          height: 32px;
          background: #3d84ff;
          border-radius: 25px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          user-select: none;
          &:active {
            background: #26b7ffa9;
          }
        }
      }
    }

    .titlecategory {
      margin-top: 20px;
      background: #fbfbfb;
      border-radius: 4px;
      padding: 30px 10px 30px 30px;
      .titlecategory-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .item-left {
          flex-basis: 80px;
          font-size: 16px;

          font-weight: bold;
          color: #333;
          user-select: none;
          white-space: nowrap;
        }
        .item-right {
          flex-grow: 1;
          flex-shrink: 1;
          .item-right-text {
            display: inline-block;
            margin-bottom: 4px;
            margin-right: 30px;
            font-size: 14px;

            font-weight: 400;
            color: #666;
            user-select: none;
            white-space: nowrap;
            cursor: pointer;
          }
          .item-right-text-heightlight {
            color: #3d84ff;
          }
        }
      }
    }
    .image-contain {
      margin-top: 10px;
      .image-item {
        margin-bottom: 50px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        .image-item-item {
          margin-bottom: 10px;
          width: calc((100% - 120px) / 6);
          float: left;
          padding: 10px;
          cursor: pointer;
          .card-contain {
            background: #ffffff;
            border-radius: 4px;
            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }
            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
            .elimage {
              width: 100%;
              height: 160px;
            }
            .card-title {
              margin-top: 15px;
              margin-left: 10px;
              font-size: 15px;
              font-weight: 500;
              color: #222222;
            }
            .card-footer {
              margin-top: 28px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .icon-title {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 400;
                color: #666666;
              }
              .icon-text {
                background-image: url(~@/assets/矩形图标.png);
                width: 108px;
                height: 24px;
                text-align: center;
                color: #288add;
              }
            }
          }
        }
      }
      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
